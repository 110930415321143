<template>
  <div class="photoCommander" style="border: 10px solid #edeff2">
    <a-spin :spinning="spin">
      <div class="live-top">
        <div class="topSty">
          <!--      <div class="live-top-input  ">-->
          <!--        <div class="topTitleColor">模板：</div>-->
          <!--        <a-select-->
          <!--            @change="templateChange"-->
          <!--            class="input"-->
          <!--            v-model="template"-->
          <!--            placeholder="请选择">-->
          <!--          <a-select-option value="">-->
          <!--            全部-->
          <!--          </a-select-option>-->
          <!--          <a-select-option-->
          <!--              v-for="templates in template_list"-->
          <!--              :value="templates.id">-->
          <!--            {{templates.name}}-->
          <!--          </a-select-option>-->
          <!--        </a-select>-->
          <!--      </div>-->




          <div class="live-top-input">
            <div class="topTitleColor">类型：</div>
            <a-radio-group v-model="type" button-style="solid" @change="selectType">
              <a-radio-button value="">
                全部
              </a-radio-button>
              <a-radio-button value="vector" @click="clickType('vector')">
                矢量图标
              </a-radio-button>
              <a-radio-button value="city" @click="clickType('city')">
                城市图标
              </a-radio-button>
            </a-radio-group>
          </div>
          <!-- <div class="live-top-input">
            <div :style="{ color: variables.topTitleColor }">类型：</div>
            <a-select
              placeholder="请选择"
              option-filter-prop="children"
              style="width: 200px"
              v-model="type"
              @change="selectType"
            >
              <a-select-option value="city">
                城市地标
              </a-select-option>
              <a-select-option value="vector">
                矢量图标
              </a-select-option>
            </a-select>
          </div> -->
<!--          <div class="live-top-input" style="margin-left: 30px;">-->
<!--            <div class="topTitleColor">名称关键字：</div>-->
<!--            <a-input-search-->
<!--              allow-clear-->
<!--              enter-button="搜索"-->
<!--              class="input"-->
<!--              @search="selectBtn"-->
<!--              @pressEnter="selectBtn"-->
<!--              v-model="templateName"-->
<!--              placeholder="请输入"-->
<!--            />-->
<!--          </div>-->
          <!--      <div class="live-top-input">-->
          <!--        <a-button type="primary" @click="selectBtn">查询</a-button>-->
          <!--        <a-button style="margin-left: 10px" class="topTitleColor" @click="restBtn">重置</a-button>-->
          <!--      </div>-->
        </div>
        <div class="lines" v-if="type !== 'vector'" ></div>
        <div class="topSty">
          <div v-if="type !== 'vector'" class="live-top-input">
            <div class="topTitleColor">省份：</div>
            <a-button style="margin: 5px;font-weight:bold;font-family: 微软雅黑" size="small" :class="{ liBackground:provincesStyle === ''}"  @click="provincesChange('','')">全部</a-button>
            <div v-for="(item,index) in province"  :key="item">
              <a-button style="margin: 5px;font-weight:bold;font-family: 微软雅黑" size="small" :class="{ liBackground:provincesStyle === index}" @click="provincesChange(item,index)">{{item}}</a-button>
            </div>
          </div>
          <div v-if="type !== 'vector'" class="live-top-input">
            <div class="topTitleColor">城市：</div>
            <a-button style="margin: 5px;font-weight:bold;font-family: 微软雅黑" size="small" :class="{ liBackground:cityStyle === ''}"  @click="cityChange('','')">全部</a-button>
            <div v-for="(item,index) in city"  :key="item">
              <a-button style="margin: 5px;font-weight:bold;font-family: 微软雅黑" size="small" :class="{ liBackground:cityStyle === index}" @click="cityChange(item,index)">{{item}}</a-button>
            </div>
          </div>
        </div>
        <div class="lines" v-if="colorList.length"></div>
        <div class="topSty" v-if="colorList.length" style="margin-bottom: 20px">
          <div class="live-top-input">
            <div class="topTitleColor">颜色搜索：</div>
            <div class="top-right">
<!--              <div v-for="list in colorList" class="colorSty" :style="{backgroundColor:list,border:selectColors === list ? '2px solid #585858' : ''}" @click="selectColor(list)">-->
              <div v-for="list in colorList" class="colorSty" :style="{backgroundColor:list,width:selectColors === list ? '45px' : '',height:selectColors === list ? '25px' : ''}" @click="selectColor(list)">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="photoCommander_card">
        <div class="card-title">
          图标元素列表
          <a-alert style="height: 40px;margin-top: 5px;margin-left: 10px"  type="info" show-icon size="small" >
            <span slot="message" style="font-weight: normal">增值服务：如需代搭建微网站、设计KV/海报、会议直播等服务，请点击 <a @click="openService">询价服务</a></span>
          </a-alert>
        </div>
        <!--    <div class="card-list" :style="{width: innerWidths > 0 && innerWidths < 1920 ? '98.5%' : '99%',}">-->
        <!--        <div class="list"-->
        <!--             :style="{width: innerWidths > 0 && innerWidths < 1920 ? '19%' : '15.6666666%',}"-->
        <!--             v-for="(card,index) in dataList"-->
        <!--             :key="index"-->
        <!--             @click="openModel(card,index)">-->
        <!--          <div style="margin-bottom: 10px;text-align: right">-->
        <!--            <a-tag :color="card.hptemName === '模版二（方块格）' ? 'blue':'green'">-->
        <!--              {{card.hptemName}}-->
        <!--            </a-tag>-->
        <!--          </div>-->
        <!--          <div style="display: flex;justify-content: center;width:100%">-->
        <!--            <div class="imgDiv">-->
        <!--              <img class="img" :src="card.previewImg">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="title">-->
        <!--            {{card.name}}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--    </div>-->

        <div v-if="dataList.length === 0">
          <NullDataPrompt message="您还没有首页图标呢 ~" />
        </div>
        <a-row v-else>
          <a-col
            :span="innerWidths > 0 && innerWidths < 1920 ? 5 : 4"
            v-for="(card, index) in dataList"
            :key="index"
            @click="openModel(card, index)"
          >
            <div class="card-list" style="margin-bottom: 10%">
              <div class="list">
                <div style="margin-bottom: 10px; text-align: right">
                  <!--            <a-tag :color="card.hptemName === '模版二（方块格）' ? 'blue':'green'">-->
                  <!--              {{card.hptemName}}-->
                  <!--            </a-tag>-->
                </div>
                <div
                  style="display: flex; justify-content: center; width: 100%"
                >
                  <div class="imgDiv">
                    <img class="img" :src="card.previewImg" />
                  </div>
                </div>
                <div class="title">
                  {{ card.name }}
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="pagination">
        <a-pagination
          show-quick-jumper
          :pageSize="innerWidths > 0 && innerWidths < 1920 ? 10 : 12"
          :current="pageTemNo"
          :total="total"
          @change="paginationChange"
        />
      </div>

      <div style="height: 10px"></div>
      <!-- 对话框-------------------------------- -->
      <a-modal
        title="图片库"
        :width="1300"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
      >
        <div class="modalDiv">
          <div class="left">
            <a-menu class="menu"
                    v-model="selectId"
                    :default-open-keys="['sub1']"
                    mode="inline">
              <a-menu-item
                v-for="(item, index) in templateList"
                @click="selectStatus(item.title,item)"
                :key="item.id"
              >
                {{ item.title }}
              </a-menu-item>
              <a-sub-menu key="sub1" title="城市图标">
                <a-menu-item
                    v-for="(list, index) in cityList"
                    @click="selectCity(list)"
                    :key="list"
                >
                  {{ list }}
                </a-menu-item>
              </a-sub-menu>

            </a-menu>
          </div>
<!--          <div v-show="selectId[0] === '1'" class="content">-->
          <div class="content">
            <a-spin :spinning="spinning">
              <div class="content-top">
                <div class="top-icon">
                  <!--   v-show="!(pageNo === 1 && iconTemplate === 0)"    -->
                  <a-icon
                    v-show="!(pageNo === 1 && iconTemplate === 0)"
                    class="icon"
                    @click="leftBtn"
                    type="left"
                  />
                </div>
                <div class="imgList">
                  <div v-for="(img, index) in iconDataList" :key="img.id">
                    <img
                      @click="getIconList(img.id, index, img.fileUrl)"
                      :style="{
                        border:
                          iconTemplate === index ? '4px solid #45a5e6' : '',
                        cursor: 'pointer',
                      }"
                      class="imgD"
                      :src="img.previewImg"
                    />
                  </div>
                </div>
                <div class="top-icon">
                  <!--   v-show="!(((pageNo-1) * pageSize + iconTemplate +1) === iconDataCount)"             -->
                  <a-icon
                    v-show="
                      !(
                        (pageNo - 1) * pageSize + iconTemplate + 1 ===
                        iconDataCount
                      ) && iconDataList.length
                    "
                    class="icon"
                    @click="rightBtn"
                    type="right"
                  />
                </div>
              </div>
              <div class="content-list">
                <div class="content-list-top">
                  <div>
                    <a-button @click="openDown" style="margin-left: 20px">
                      <a-icon type="cloud-download" />下载源文件</a-button
                    >
<!--                    <a-button @click="collectBtn" style="margin-left: 20px">-->
<!--                      <a-icon type="heart" />收藏全部</a-button-->
<!--                    >-->
                  </div>
                  <a-input-search
                    allowClear
                    v-model="iconName"
                    style="width: 300px"
                    placeholder="请输入功能名称"
                    enter-button="搜索"
                    @search="onSearch"
                  />
                </div>
                <div class="content-list-card">
                  <div
                    @click="selectCard(index, img.url)"
                    class="card"
                    :style="{
                      border: selectIndex === index ? '4px solid #45a5e6' : '',
                      cursor: 'pointer',
                    }"
                    v-for="(img, index) in funList"
                    :key="img.id"
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        position: relative;
                      "
                    >
                      <img class="imgD" :src="img.url" />
<!--                      <a-icon-->
<!--                        @click="collectionBtn(img.id)"-->
<!--                        v-if="!img.isMyCollection"-->
<!--                        style="-->
<!--                          position: absolute;-->
<!--                          right: 8px;-->
<!--                          top: 8px;-->
<!--                          color: white;-->
<!--                        "-->
<!--                        type="heart"-->
<!--                      />-->
<!--                      <admin_con-->
<!--                        v-else-->
<!--                        style="position: absolute; right: 8px; top: 8px"-->
<!--                        type="icon-jushoucanggift"-->
<!--                      />-->
                    </div>

                    <div class="font" style="margin-top: 10px">
                      {{ img.name }}
                    </div>
                  </div>
                </div>
              </div>
            </a-spin>
          </div>
<!--          <div v-show="selectId[0] === '2'" class="content">-->
<!--            <a-spin :spinning="spinning">-->
<!--              <div class="content-two-list">-->
<!--                <div class="content-two-list-top">-->
<!--                  <div style="position: relative; width: 140px; height: 35px">-->
<!--                    <a-button-->
<!--                      style="margin-left: 1%"-->
<!--                      @click="openModelPic('upPic')"-->
<!--                    >-->
<!--                      <a-icon type="vertical-align-top" />-->
<!--                      批量上传图片-->
<!--                    </a-button>-->
<!--                    &lt;!&ndash;              <input&ndash;&gt;-->
<!--                    &lt;!&ndash;                  multiple&ndash;&gt;-->
<!--                    &lt;!&ndash;                  ref="batchImg"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  style="position: absolute;top: 0;&ndash;&gt;-->
<!--                    &lt;!&ndash;                     opacity: 0;width: 140px;&ndash;&gt;-->
<!--                    &lt;!&ndash;                     height: 35px;left: 0"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  accept="image/*"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  type="file"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  @change="batchImgBtn('batchImg')" />&ndash;&gt;-->
<!--                  </div>-->
<!--                  <a-input-search-->
<!--                    allowClear-->
<!--                    v-model="myFunName"-->
<!--                    style="width: 300px"-->
<!--                    placeholder="请输入功能名称"-->
<!--                    enter-button="搜索"-->
<!--                    @search="MySearch"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="content-two-list-card">-->
<!--                  <div class="addCard">-->
<!--                    <div class="up" @click="openModelPic('upOnePic')">-->
<!--                      <div style="font-size: 40px; color: #bdbcbc">+</div>-->
<!--                      <div style="font-size: 16px; color: #929292">上传</div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;              <div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                <input&ndash;&gt;-->
<!--                    &lt;!&ndash;                    id="surfacePlot"&ndash;&gt;-->
<!--                    &lt;!&ndash;                    ref="surfacePlot"&ndash;&gt;-->
<!--                    &lt;!&ndash;                    class="inputImg"&ndash;&gt;-->
<!--                    &lt;!&ndash;                    accept="image/*"&ndash;&gt;-->
<!--                    &lt;!&ndash;                    type="file"&ndash;&gt;-->
<!--                    &lt;!&ndash;                    @change="surfacePlotBtn" />&ndash;&gt;-->
<!--                    &lt;!&ndash;              </div>&ndash;&gt;-->

<!--                    <div class="imgSty">-->
<!--                      <img-->
<!--                        v-if="funUp.funImg"-->
<!--                        class="img"-->
<!--                        :src="funUp.funImg"-->
<!--                        alt=""-->
<!--                      />-->
<!--                    </div>-->
<!--                    <div v-if="funUp.funImg" class="upSave" @click="saveBtnTem">-->
<!--                      保存-->
<!--                    </div>-->

<!--                    <div class="upInput">-->
<!--                      <a-input-->
<!--                        style="margin-top: 96%"-->
<!--                        v-model="funUp.funName"-->
<!--                        placeholder="请输入功能名称"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div>-->
<!--                    <div-->
<!--                      class="card"-->
<!--                      v-for="(img, index) in myFunList"-->
<!--                      @click="getSelectMyIcon(index, img.url)"-->
<!--                      :style="{-->
<!--                        border:-->
<!--                          selectMyIndex === index ? '2px solid #45a5e6' : '',-->
<!--                        cursor: 'pointer',-->
<!--                      }"-->
<!--                      :key="img.id"-->
<!--                    >-->
<!--                      <div class="imgTitle">-->
<!--                        <div>-->
<!--                          <div-->
<!--                            style="-->
<!--                              display: flex;-->
<!--                              justify-content: center;-->
<!--                              width: 100%;-->
<!--                            "-->
<!--                          >-->
<!--                            <img class="imgD" :src="img.url" />-->
<!--                          </div>-->
<!--                          <div style="text-align: center; margin-top: 10px">-->
<!--                            <a-input-->
<!--                              id="Ainput"-->
<!--                              @blur="pressEnter(img)"-->
<!--                              @pressEnter="pressEnter(img)"-->
<!--                              style="width: 93%; margin: auto"-->
<!--                              v-if="img.editKey"-->
<!--                              v-model="img.name"-->
<!--                            />-->
<!--                            <span class="font" v-else>{{ img.name }}</span>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="maskLayer" v-show="!img.editKey">-->
<!--                          <div style="font-size: 20px; text-align: right">-->
<!--                            <a-icon-->
<!--                              @click="putIconList(img)"-->
<!--                              style="margin-right: 10px"-->
<!--                              type="edit"-->
<!--                            />-->
<!--                            <a-popconfirm-->
<!--                              placement="bottomRight"-->
<!--                              title="是否确认删除?"-->
<!--                              ok-text="是"-->
<!--                              cancel-text="否"-->
<!--                              @confirm="delIconList(img.id)"-->
<!--                            >-->
<!--                              <a-icon-->
<!--                                style="margin-right: 10px"-->
<!--                                type="close-circle"-->
<!--                              />-->
<!--                            </a-popconfirm>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a-spin>-->
<!--          </div>-->
        </div>
        <!--    </a-spin>-->
      </a-modal>
      <pictureDialog
        :visible="visiblePic"
        v-on:closeMain="closeMain"
        v-on:checkList="checkList"
      >
      </pictureDialog>
      <MessageAlert
        :visible="visibleAlert"
        v-on:closeMain="closeMainAlert"
      ></MessageAlert>

      <!--      服务介绍
          免费搭建微网站服务code：mfdjwwz
          （收费）代理搭建微网站服务code：wwzdlfw   目前写死此code-->
      <LiveServiceIntro
          v-if="LiveIntroVisible"
          :visible="LiveIntroVisible"
          :serviceCode="serviceCode"
          v-on:closeMain="closeMainIntro"
          v-on:openExclusive="openExclusive"
      />

      <!--  专属客服-->
      <ServiceExclusive
          v-if="visibleExclusives"
          :visible="visibleExclusives"
          v-on:closeMain="closeMainExclusives"
      />
<!--      图标详情页-->
      <IconDetail
          v-if="visibleIconDetail"
          :visible="visibleIconDetail"
          :listObj="IconDetailList"
          v-on:closeMainIconDetail="closeMainIconDetail"
      />
    </a-spin>
  </div>
</template>

<script>
import ServiceExclusive from "@/components/PaidView/ServiceExclusive";
import LiveServiceIntro from "@/components/PaidView/LiveServiceIntro"
import variables from "@/assets/base.scss";
import { getTemplateTitle } from "@/service/medicalConference_api";
import { update } from "@/utils/update";
import {
  addMyCollection,
  addMyCollectionByItemId,
  bach_icon,
  delIcon, getIconCity, getIconColor, getIconProvince, getlconCity,
  iconList,
  icontemp,
  postIcon,
  putIcon,
} from "@/service/photoCommander";
import { postImgList } from "@/service/document_api";
import pictureDialog from "@/components/Material/pictureDialog";
import NullDataPrompt from "@/components/NullDataPrompt";
import { admin_icon } from "@/utils/myIcon";
import {addReportPb, getAuthenticationInformation} from "@/service/authentication";
import MessageAlert from "@/components/Authentication/MessageAlert";
import IconDetail from "@/components/meet/IconDetail";

export default {
  name: "photoCommander",
  components: {
    NullDataPrompt,
    pictureDialog,
    admin_con: admin_icon,
    MessageAlert,
    ServiceExclusive,
    LiveServiceIntro,
    IconDetail
  },
  data() {
    return {

      visibleAlert: false,
      userInfoList: {},
      variables,
      openModelType: null,
      picList: [],
      template_list: [],
      dataList: [],
      total: 0,
      visiblePic: false,
      visible: false,
      spin: false,
      spinning: false,
      cardId: null,
      selectId: ["vector"],
      //城市图标 city 矢量图标 vector
      templateList: [
        {
          id: "vector",
          title: "矢量图标",
        },
        // {
        //   id: "1",
        //   title: "系统图标",
        // },
      ],
      cityList:[],
      funList: [],
      isImgSty: "imgTitle",
      iconName: "",
      // isImgSty:'img',
      funUp: {
        funName: "",
        funImg: "",
      },
      curPage: 1,
      createdById: null,
      pageTemNo: 1,
      // pageTemSize:8,

      myFunList: [],
      pageNo: 1,
      pageSize: 5,
      template: "",
      templateName: "",
      iconDataList: [],
      iconDataCount: 0,
      iconTemplate: 0,
      iconTemplateId: null,
      downLoadFile: "",
      myFunName: "",
      //最终选择的图片
      selectImg: "",
      selectIndex: null,
      selectMyIndex: null,
      innerWidths: null,

      type:'',
      serviceCode:'',
      LiveIntroVisible:false,

      visibleExclusives:false,

      isKefu:null,

      colorList:['#fc0000','#00ff36','#ff00f2','#00fff5'],
      selectColors:'',

      province:[],
      provincesName:'',
      provincesStyle:'',

      city:[],
      cityName:'',
      cityStyle:'',

      visibleIconDetail:false,
      IconDetailList:{},
    };
  },
  created() {
    this.innerWidths = window.innerWidth; //屏幕宽度
    const user_info = window.localStorage.getItem("yichi_userInfo");
    this.createdById = JSON.parse(user_info).id;

    this.isKefu = user_info.isKefu;

    this.getTemList();
    this.getTemplateList();
    this.getInformation();
    // console.log('屏幕宽度',window.innerWidth)

    this.getIconProvinces();
    this.getIconColors();

  },
  methods: {
    closeMainIconDetail(val){
      this.visibleIconDetail = val;
    },
    //获取省份列表
    async getIconProvinces() {
      const response = await getIconProvince();
      if (response.code === 200) {
        this.province = response.data;
        await this.getIconCitys();
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取城市列表
    async getIconCitys() {
      let data = {
        province:this.provincesName
      }
      const response = await getIconCity(this.provincesName);
      if (response.code === 200) {
        this.city = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取颜色列表
    async getIconColors() {
      let data = {
        type:this.type,
        province:this.type !== 'vector' ? this.provincesName:'',
        city:this.type[0] !== 'vector' ? this.cityName:'',
      }
      const response = await getIconColor(data);
      if (response.code === 200) {
        this.colorList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    provincesChange(name,index){
      this.provincesName = name;
      this.provincesStyle = index;
      this.cityStyle = '';
      this.cityName = '';
      this.getIconCitys();
      this.pageTemNo = 1;

      this.selectColors = '';
      this.getIconColors();
      this.getTemplateList();
    },
    cityChange(name,index){
      this.cityName = name;
      this.cityStyle = index;
      this.pageTemNo = 1;

      this.selectColors = '';
      this.getIconColors();
      this.getTemplateList();
    },
    selectColor(color){
      if(this.selectColors === color){
        this.selectColors = '';
      }else {
        this.selectColors = color;
      }
      this.pageTemNo = 1;
      this.getTemplateList();
    },
    //埋点
    async postAddReportPb(location) {
      let data = {
        "serviceCode":this.serviceCode, //浏览商品code值
        // "browseTime":this.seconds, //浏览时间
        "location":location //浏览位置
      }
      await addReportPb(data);
    },
    openExclusive(){
      this.visibleExclusives = true;
    },
    closeMainIntro(val) {
      this.LiveIntroVisible = val;
    },
    closeMainExclusives(val) {
      this.visibleExclusives = val;
    },
    async openService() {
      this.serviceCode = 'wwzdlfw'; //服务咨询
      this.LiveIntroVisible = true;

      if(!this.isKefu){
        await this.postAddReportPb('CONVENTION_ICONIC_ELEMENTS');
      }
    },
    async getInformation() {
      const response = await getAuthenticationInformation();
      if (response.code === 200) {
        this.userInfoList = response.data;
      } else {
        this.$message.warning("name", response.message);
      }
    },
    openModelPic(type) {
      this.openModelType = type;
      this.visiblePic = true;
    },
    closeMain(val) {
      this.visiblePic = val;
    },
    checkList(val) {
      // this.picList = val[0].imageUrl;
      this.picList = val;
      let type = this.openModelType;
      if (type === "upOnePic") {
        // 封面图
        this.funUp.funImg = this.picList[0].url;
        this.funUp.funName = this.picList[0].name;
      } else if (type === "upPic") {
        //背景图
        let data = [];
        for (let i = 0; i < this.picList.length; i++) {
          let list = {
            url: this.picList[i].url,
            name: this.picList[i].name,
            // icontempId: this.cardId,
            createdById: this.createdById,
          };
          data.push(list);
        }
        this.upImgList(data);
      }
    },
    //获取列表
    async getTemplateList() {
      this.spin = true;
      let data = {
        curPage: this.pageTemNo,
        pageSize: this.innerWidths > 0 && this.innerWidths < 1920 ? 10 : 12,
        hptempId: this.template,
        isOpen: 1,
        name: this.templateName,
        type:this.type,//类型
        color:this.selectColors, //颜色搜索
        province: this.type === 'vector' ? '': this.provincesName, //矢量图不传值
        city: this.type === 'vector' ? '': this.cityName
      };
      const response = await icontemp(data);
      if (response.code === 0) {
        this.dataList = response.data;
        this.total = response.count;
      } else {
        this.$message.warning(response.message);
      }
      this.spin = false;
    },
    selectType(){
      this.pageTemNo = 1;
      this.getIconColors();
      this.getTemplateList();
    },
    clickType(val){
      if(val === this.type){
        this.type = '';
        this.pageTemNo = 1;
        this.getTemplateList();
      }
    },
    selectBtn() {
      this.pageTemNo = 1;
      this.getTemplateList();
    },
    restBtn() {
      this.pageTemNo = 1;
      this.template = "";
      this.templateName = "";
      this.getTemplateList();
    },
    paginationChange(page_no, page_size) {
      this.pageTemNo = page_no;
      this.getTemplateList();
    },
    //type 类型（城市图标 city 矢量图标 vector） 和 city城市
    async getList(type,card) {
      let data = {
        curPage: this.pageNo,
        pageSize: this.pageSize,
        hptempId: this.template,
        type:this.selectId[0] === 'vector' ? 'vector':'city',
        city:this.selectId[0] === 'vector' ? null : this.selectId[0],
        isOpen: 1,
      };
      const response = await icontemp(data);
      if (response.code === 0) {
        this.iconDataList = response.data;
        this.iconDataCount = response.count;

          if (this.iconDataList.length) {
            if(type === 'city' || type === 'vector'){ //城市定位
              let isNext = true;
              response.data.forEach((item,index)=>{
                if(card.id === item.id){
                  isNext = false; //停止进入下一组循环
                  this.iconTemplate = index;
                }
              })
              if(isNext) {
                this.pageNo ++ ;
                this.getList(type,card);
              }
            }

          //默认情况下为数组第一个源文件
          // this.downLoadFile = response.data[this.iconTemplate].fileUrl
          //默认情况下获取数组一下的图标
          await this.getIconList(
            response.data[this.iconTemplate].id,
            this.iconTemplate,
            response.data[this.iconTemplate].fileUrl
          );
        }

      } else {
        this.$message.warning("上传失败");
      }
    },
    async openModel(card, index) {
      //打开图片详情页面
      this.IconDetailList = card;
      this.visibleIconDetail = true;
    },
    selectStatus(val,item) {
      // this.spinning = true;
      // if (val === "我的上传") {
      //   let data = {
      //     createdById: this.createdById,
      //     name: this.myFunName,
      //     curPage: this.curPage,
      //     pageSize: 99999,
      //     // icontempId:-1,
      //   };
      //   const response = await iconList(data);
      //   if (response.code === 0) {
      //     this.myFunList = response.data;
      //   } else {
      //     this.$message.warning(response.message);
      //   }
      // } else {

      // }
      //清空选中的index

      // this.selectId[0] = item.id;
      // this.funList = [];
      // this.selectIndex = null;
      // this.selectMyIndex = null;
      // this.selectImg = "";
      // this.spinning = false;
      // this.iconTemplate = 0;
      this.selectId[0] = 'vector';
      this.funList = [];
      this.iconTemplate = 0;
      this.pageNo = 1;
      this.getList();

    },
    selectCity(val){
      this.selectId[0] = val;
      this.funList = [];
      this.iconTemplate = 0;
      this.pageNo = 1;
      this.getList();
    },
    async getlconCityList() {
      const response = await getlconCity();
      if (response.code === 200) {
        this.cityList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    templateChange() {
      this.getTemplateList();
    },
    handleCancel() {
      // console.log('关闭')
      this.iconTemplate = 0;
      this.pageNo = 1;
      // this.selectId = ["vector"];
      this.visible = false;
    },
    MySearch() {
      this.selectStatus("我的上传");
    },
    async surfacePlotBtn() {
      this.spinning = true;
      let inputDOM = this.$refs.surfacePlot.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM);
        if (image.code === 0) {
          this.funUp.funImg = image.data.url;
          this.funUp.funName = inputDOM.name;
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
      } else {
        this.$message.warning("请上传小于1M的图片！");
      }
      document.getElementById("surfacePlot").value = null;
      this.spinning = false;
    },
    //批量上传图片
    async batchImgBtn(name) {
      this.spinning = true;
      let data = [];
      let fileList = this.$refs[name].files;
      let formData = new FormData();

      for (const file of fileList) {
        let list = {
          url: "",
          name: file.name,
          // icontempId: this.cardId,
          createdById: this.createdById,
        };
        data.push(list);
        formData.append("file", file);
      }
      // 判断图片大小
      const image = await postImgList(formData);
      if (image.code === 0) {
        for (let j = 0; j < image.data.url.length; j++) {
          data[j].url = image.data.url[j];
        }
        await this.upImgList(data);
      } else {
        this.$message.warning("上传失败");
      }
      // document.getElementById('batchImg').value = null
    },
    //批量上传图片接口
    async upImgList(data) {
      const response = await bach_icon(data);
      if (response.code === 0) {
        await this.selectStatus("我的上传");
        this.$message.success("操作成功！");
      } else {
        this.$message.warning(response.message);
      }
    },
    //  获取列表 --- 模板列表
    async getTemList() {
      const Info = await getTemplateTitle();
      if (Info.code === 0) {
        // this.template_list = response.data
        for (let i = 0; i < Info.data.length; i++) {
          if (Info.data[i].isOpen === 1) {
            this.template_list.push({
              id: Info.data[i].id,
              isBanner: Info.data[i].isBanner,
              isBgimg: Info.data[i].isBgimg,
              isNotice: Info.data[i].isNotice,
              isOpen: Info.data[i].isOpen,
              name: Info.data[i].name,
            });
          }
        }
      }
    },
    onSearch() {
      this.getTemplateIcon(this.iconTemplateId);
    },
    leftBtn() {
      // 左边按钮
      if (this.iconTemplate > 0) {
        this.iconTemplate--;
        this.getTemplateIcon(this.iconDataList[this.iconTemplate].id);
      } else {
        //返回上一页九宫格模板的数据，并且初始index为4
        this.iconTemplate = 4;
        this.pageNo--;
        this.getList();
      }
    },
    rightBtn() {
      // 右边按钮
      if (this.iconTemplate < this.iconDataList.length - 1) {
        this.iconTemplate++;
        this.getTemplateIcon(this.iconDataList[this.iconTemplate].id);
        this.downLoadFile = this.iconDataList[this.iconTemplate].fileUrl;
      } else {
        //加载下一页九宫格模板的数据
        if (this.pageNo * this.pageSize < this.iconDataCount) {
          this.iconTemplate = 0;
          this.pageNo++;
          this.getList();
        }
      }
    },
    getIconList(id, index, fileUrl) {
      //获取图标
      this.downLoadFile = fileUrl;
      this.iconTemplate = index ? index : 0;
      this.iconTemplateId = id;
      this.getTemplateIcon(id);
    },
    //获取图标数据
    async getTemplateIcon(id) {
      this.spinning = true;
      this.iconTemplateId = id;
      let data = {
        // createdById:'',
        name: this.iconName,
        curPage: this.curPage,
        pageSize: 99999,
        icontempId: id, //九宫格模板id
      };
      const response = await iconList(data);
      if (response.code === 0) {
        this.funList = response.data;
      } else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    openDown() {
      if (this.userInfoList.memberStatus !== 0) {
        window.open(this.downLoadFile);
      } else {
        this.visibleAlert = true;
      }
      // if (!this.userInfoList.id) {
      //   this.visibleAlert = true;
      // }else {
      //   window.open(this.downLoadFile);
      // }
    },
    async collectBtn() {
      if (this.userInfoList.memberStatus !== 0) {
        let list = this.funList;
        //判断当已全部收藏没有为收藏时，提醒用户
        if (list.some((item) => !item.isMyCollection)) {
          this.spinning = true;
          const response = await addMyCollectionByItemId(this.iconTemplateId);
          if (response.code === 200) {
            this.$message.success("收藏成功！");
            await this.getTemplateIcon(this.iconTemplateId);
          } else {
            this.$message.warning(response.message);
          }
        } else {
          this.$message.warning("已全部收藏！");
        }
      } else {
        this.visibleAlert = true;
      }
    },
    async collectionBtn(id) {
      if (this.userInfoList.memberStatus !== 0) {
        const response = await addMyCollection(id);
        if (response.code === 200) {
          this.$message.success("收藏成功！");
          await this.getTemplateIcon(this.iconTemplateId);
        } else {
          this.$message.warning(response.message);
        }
      } else {
        this.visibleAlert = true;
      }
    },
    selectCard(index, url) {
      this.selectIndex = index;
      this.selectImg = url;
    },
    getSelectMyIcon(index, url) {
      this.selectMyIndex = index;
      this.selectImg = url;
    },
    async saveBtnTem() {
      if (this.funUp.funName) {
        let data = {
          // icontempId:this.cardId,
          url: this.funUp.funImg,
          name: this.funUp.funName,
          createdById: this.createdById,
        };
        const response = await postIcon(data);
        if (response.code === 0) {
          this.$message.success("新增成功！");
          await this.selectStatus("我的上传");
          this.funUp = {
            funImg: "",
            funName: "",
          };
        } else {
          this.$message.warning(response.message);
        }
      } else {
        this.$message.info("请先输入功能名称！");
      }
    },
    async pressEnter(img) {
      let data = {
        id: img.id,
        name: img.name,
        createdById: this.createdById,
      };
      const response = await putIcon(data);
      if (response.code === 0) {
        await this.selectStatus("我的上传");
      } else {
        this.$message.warning(response.message);
      }
      this.$set(img, "editKey", false);
    },
    blurEnter(img) {
      this.$set(img, "editKey", false);
    },
    putIconList(img) {
      this.$set(img, "editKey", true);
      //自动聚焦
      this.$nextTick(() => {
        document.getElementById("Ainput").focus();
      });
    },
    async delIconList(id) {
      const response = await delIcon(id);
      if (response.code === 0) {
        await this.selectStatus("我的上传");
        this.$message.success("操作成功！");
      } else {
        this.$message.warning(response.message);
      }
    },
    closeMainAlert(val) {
      this.visibleAlert = val;
    },
  },
};
</script>

<style scoped lang="scss">
.liBackground {
  background: #1890ff !important;
  color: white;
}
.ant-btn{
  border: none !important;
  box-shadow:none !important;
}
.ant-col-5 {
  width: 20%;
}
.ant-col-4 {
  width: 16.66666666%;
}
.photoCommander {
  width: 100%;
  height: auto;
  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .live-top {
    min-height: 75px;
    width: 100%;
    border-bottom: 10px solid #edeff2;
    padding-top: 15px;
    .topTitleColor {
      color: $topTitleColor;
    }
    .topSty {
      width: $space_div;
      margin: auto;
      //display: flex;
      //justify-content: space-between;
    }
    .top-right{
      display: flex;
      height: 35px;
      align-items: center;
      //padding-top: 8px;
      //margin-top: 7px;
      padding-left: 10px;
      background-color: #F5F5FF;
    }
    .colorSty{
      width: 40px;
      height: 20px;
      border-radius: 3px;
      margin-right: 10px;
      cursor: pointer;
    }
    .lines{
      height: 1px;
      background-color: #e8e8e8;
      width: 99%;
      margin: auto;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .live-top-input {
      display: flex;
      height: 35px;
      line-height: 35px;

      .input {
        width: 250px;
        margin-left: 5px;
      }
    }
  }
  .photoCommander_card {
    width: 100%;
    min-height: 650px;
    margin-bottom: 20px;
    .card-title {
      display: flex;
      height: 50px;
      padding-left: 1%;
      line-height: 50px;
      font-weight: 600;
      //padding: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #edeff2;
    }
    .card-list {
      width: 100%;
      margin: auto;
      //min-height: 200px;
      .list {
        width: 89%;
        margin: auto;
        cursor: pointer;
        height: auto;
        //margin-right: 0.5%;
        //margin-left: 0.5%;
        border-radius: 5px;
        border: 1px solid #e0dede;
        padding: 15px 15px 15px 15px;
        .imgDiv {
          width: 98%;
          padding-top: 98%;
          position: relative;
        }
        .img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }
        .title {
          width: 100%;
          text-align: center;
          margin-top: 15px;
          height: 20px;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          word-break: break-all;
        }
      }
    }
  }
}
.modalDiv {
  width: 100%;
  display: flex;
  margin-top: -24px;
  margin-left: -24px;
  .menu {
    width: 150px;
    border: none;
  }
  .content {
    width: 100%;
    border-left: 1px solid #e7e7e7;
    .content-top {
      height: 200px;
      border-bottom: 1px solid #e7e7e7;
      display: flex;
      .top-icon {
        width: 10%;
        text-align: center;
        .icon {
          line-height: 200px;
          font-size: 40px;
          color: #929292;
          cursor: pointer;
        }
      }
      .imgList {
        width: 80%;
        display: flex;
        margin-top: 20px;
        .imgD {
          height: 160px;
          width: 150px;
          margin-right: 30px;
        }
      }
    }
    .content-list {
      height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      .content-list-top {
        width: 98%;
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0 0;
      }
      .content-list-card {
        width: 100%;
        margin-top: 20px;
        margin-left: 10px;
        .card {
          width: 15%;
          height: 180px;
          float: left;
          margin-left: 1%;
          margin-bottom: 1%;
          background-color: rgba(0, 0, 0, 0.85);
          border: 1px solid #dcdbdb;
          .imgD {
            //width: 6vw;
            //height: 6vw;
            width: 130px;
            height: 120px;
            margin-top: 10%;
          }
          .font {
            color: #ffffff;
            width: 98%;
            margin-left: 2px;
            text-align: center;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .content-two-list {
    .content-two-list-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 20px 0 0 10px;
    }
    .content-two-list-card {
      width: 100%;
      height: 425px;
      overflow-y: scroll;
      margin-top: 20px;
      margin-left: 10px;
      .addCard {
        width: 15%;
        border: 1px solid #dcdbdb;
        height: 180px;
        float: left;
        margin-left: 1%;
        margin-bottom: 1%;
        position: relative;
        .up {
          width: 100%;
          text-align: center;
          cursor: pointer;
          z-index: 999999;
          position: absolute;
          top: 0;
          height: 80%;
        }
        .inputImg {
          width: 100%;
          height: 80%;
          opacity: 0;
          z-index: 999999;
          position: absolute;
          top: 0;
        }
        .upSave {
          position: absolute;
          top: 0;
          right: 10px;
          color: #45a5e6;
          cursor: pointer;
          z-index: 9999999999;
        }
        .imgSty {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          .img {
            width: 100%;
            height: 80%;
            background-color: #fff;
          }
        }
        .upInput {
          width: 93%;
          margin: auto;
        }
      }
      .card {
        width: 15%;
        height: 180px;
        float: left;
        margin-left: 1%;
        margin-bottom: 1%;
        border: 1px solid #dcdbdb;
        .imgTitle {
          width: 100%;
          height: 100%;
          position: relative;
        }
        .maskLayer {
          z-index: 9999999;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          color: #fff;
          background: rgba(0, 0, 0, 0.4);
          text-align: center;
          opacity: 0; //透明度为0 不显示遮罩层
        }
        .maskLayer:hover {
          opacity: 1;
        }
        .imgD {
          //width: 6vw;
          //height: 6vw;
          width: 130px;
          height: 120px;
          margin-top: 10%;
        }
        .font {
          width: 98%;
          margin-left: 2px;
          text-align: center;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
