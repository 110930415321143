<template>
<div>
<!--  搭建会议首页 --- 服务介绍-->
  <a-modal v-model="visible" width="800px" :footer="null" @cancel="handleClose">
    <template slot="title">
      <span style="font-weight: 500;color: #333333;font-size: 18px">服务介绍</span>
    </template>
   <div class="contents" style="margin-bottom: 50px">
     <div class="contentTop">
       <div class="topLeft">
         <img src="../../assets/paidImg/service.png"  class="topImg"/>
       </div>
       <div class="topRight">
         <div class="rightTitle">{{serviceInfo.name}}</div>
         <div class="rightCont">{{serviceInfo.shortIntro}}</div>
         <div class="rightPie">
           价格 <span style="color: #f4742f">￥{{serviceInfo.downPrice}} 元 ~ {{serviceInfo.upPrice}} 元</span>
         </div>
       </div>
     </div>
     <div class="contentTab">
       <a-tabs default-active-key="1">
         <a-tab-pane key="1" tab="服务介绍">
           <div v-if="serviceInfo.serviceIntroduction" v-html="serviceInfo.serviceIntroduction"></div>
         </a-tab-pane>
         <a-tab-pane key="2">
           <span slot="tab">
             <span>用户评价（{{servicePingJia.length}}）</span>
           </span>

           <div v-for="item in servicePingJia" >
             <div style="display: flex;margin-bottom: 10px">
               <img src="../../assets/paidImg/childImg.png" style="width: 23px;"/>
<!--               <div style="margin-left: 7px">{{phoneBtn('15634126353')}}</div>-->
               <div style="margin-left: 7px">{{item.memberTel}}</div>
             </div>
             <div>
               <a-tag color="orange" v-for="(tag,index) in item.evaluate" :key="index">{{tag}}</a-tag>
             </div>
             <div style="display: flex">
               <div style="margin-top: 5px;margin-bottom: 5px;">服务评价：{{item.serviceEvaluate}}</div>
               <div style="margin: 5px 20px;">客服评价：
                 <admin_con :type="item.kfEvaluate >=1 ? 'icon-xingxing1':'icon-xingxing'"/>
                 <admin_con :type="item.kfEvaluate >=2 ? 'icon-xingxing1':'icon-xingxing'"/>
                 <admin_con :type="item.kfEvaluate >=3 ? 'icon-xingxing1':'icon-xingxing'"/>
                 <admin_con :type="item.kfEvaluate >=4 ? 'icon-xingxing1':'icon-xingxing'"/>
                 <admin_con :type="item.kfEvaluate >=5 ? 'icon-xingxing1':'icon-xingxing'"/>
               </div>
               <div style="margin-top: 5px;margin-bottom: 5px;">系统评价：
                 <admin_con :type="item.systemEvaluate >=1 ? 'icon-xingxing1':'icon-xingxing'"/>
                 <admin_con :type="item.systemEvaluate >=2 ? 'icon-xingxing1':'icon-xingxing'"/>
                 <admin_con :type="item.systemEvaluate >=3 ? 'icon-xingxing1':'icon-xingxing'"/>
                 <admin_con :type="item.systemEvaluate >=4 ? 'icon-xingxing1':'icon-xingxing'"/>
                 <admin_con :type="item.systemEvaluate >=5 ? 'icon-xingxing1':'icon-xingxing'"/>
               </div>
             </div>
             <div style="border-bottom: 1px solid #f5f5f5;margin-bottom: 10px;padding-bottom: 10px">时间：{{item.createdTime}}</div>
           </div>
         </a-tab-pane>
       </a-tabs>
     </div>
   </div>
    <div class="bottomSty">
      <a-button @click="handleClose">
        取消
      </a-button>
      <a-button style="margin-left: 10px" type="primary" @click="handleOk">
        免费咨询
      </a-button>
    </div>
  </a-modal>

</div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";
import {getServiceInfo, getServicePingJia, receiveService} from "@/service/authentication";
export default {
  name: "ServiceIntroduction",
  components:{
    admin_con: admin_icon,
  },
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    serviceCode:{
      type:String,
      default:'',
    },
  },
  data(){
    return{
      serviceIntro:'<p>11111111111</p>',
      serviceInfo:{}, //服务介绍
      servicePingJia:[], //服务评价
    }
  },
  created() {
    this.getInformation();
    this.servicePingJias();
  },
  methods:{
    //获取服务介绍
    async getInformation() {
      const response = await getServiceInfo(this.serviceCode);
      if (response.code === 200) {
        this.serviceInfo = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取服务评价
    async servicePingJias() {
      const response = await getServicePingJia(this.serviceCode);
      if (response.code === 200) {
        this.servicePingJia = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    //领取服务
    async postReceiveService() {
      let data = {
        code:'wwzdlfw',
      }
      const response = await receiveService(data);
      if (response.code === 200) {
        //打开专属客服，关闭当前弹窗
        let visible = true;
        this.$emit("openExclusive", visible); //子组件向父组件传值（开关）
        this.handleClose();
      } else {
        this.$message.warning(response.message);
      }
    },
    async handleOk() {
      this.postReceiveService();
    },
    handleClose(){
      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
    },
  }
}
</script>

<style scoped>
.bottomSty {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background-color: #fff;
  display: flex;
  justify-content: right;
  z-index: 1;
}
.contents{
  height: 500px;
  overflow-y: scroll;
}
.contentTop{
  display: flex;
  justify-content: space-between;
}
.topLeft{

}
.topRight{
  width: 530px;
}
.rightTitle{
  font-weight: bold;
  margin-bottom: 10px;
  color: #333333;
}
.rightCont{
  color: #333333;
  margin-bottom: 10px;
}
.topImg{
  width: 200px;
}
.rightPie{
  background-image: url('../../assets/paidImg/piceImg.jpg');
  background-size: 100% 40px;
  background-repeat: no-repeat;
  width: 530px;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  color: #333333;
}
.piceImg{
  width: 400px;
  height: 50px;
}
.contentTab{

}
</style>
