<template>
  <div>
    <!--图标详情页-->
    <a-modal
        :dialogStyle="{ 'top': '5%',}"
        width="900px"
        title="图片库"
        :visible="visible"
        :footer="false"
        @cancel="handleCancel"
    >
      <div class="modalDiv">
        <div class="content">
          <a-spin :spinning="spin">
            <div class="content-list">
              <div class="content-list-left">
                <div style="font-weight: bold;display: flex;justify-items: center">
                  <a-icon style="font-size: 23px;margin-right: 10px;" @click="returnBtn" type="left-circle" />
                  <div style="margin-top: -2px;">{{listObj.name}}</div>
                </div>
                <div>
                  <img style="width: 230px;height: auto;margin-top: 20px;border: 1px solid #F5F5F5" :src="listObj.previewImg" alt="">
                </div>
              </div>
              <div class="content-list-card">
                <a-row :gutter="[16,16]">
                  <a-col :span="5" v-for="(img, index) in funList" :key="img.id">
                    <div class="content-list-card-imgList">
                      <img
                          class="content-list-card-imgD"
                          :src="img.url"/>
                      <div class="font">{{ img.name }}</div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-spin>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {getlconCity, iconList, icontemp} from "@/service/photoCommander";

export default {
  name: "SystemIcons",
  data(){
    return{
      funList: [],
      spin:false,
      pageNo:1,
      iconTemplate:0,
      iconDataCount: 0,
      iconName:'',
      //最终选择的图片
      selectImg: "",
      selectIndex: null,
      iconDataList: [],
      pageSize: 999999,
      curPage: 1,
    }
  },
  props: {
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
    listObj:{
      type: Object,
    }
  },
  created() {
    this.getTemplateIcon();
  },
  methods:{
    //获取图标数据
    async getTemplateIcon() {
      this.spin = true;
      let data = {
        // name: this.iconName,
        curPage: this.curPage,
        pageSize: 99999,
        icontempId: this.listObj.id, //九宫格模板id
      };
      const response = await iconList(data);
      if (response.code === 0) {
        this.funList = response.data;
      } else {
        this.$message.warning(response.message);
      }
      this.spin = false;
    },
    handleCancel(){
      let flag = false;
      this.$emit("closeMainIconDetail", flag); //子组件向父组件传值（开关）
    },
    returnBtn(){
      this.funList = [];
      this.handleCancel();
    }
  }
}
</script>

<style scoped lang="scss">
.ant-col-5 {
  width: 20%;
}
.modalDiv {
  width: 103.8%;
  height: 600px;
  display: flex;
  margin-top: -24px;
  margin-left: -24px;
  .menu {
    width: 150px;
    border: none;
  }
  .content {
    width: 100%;
    //background-color: #fff;
    border-left: 1px solid #e7e7e7;
    .content-top {
      //height: 200px;
      //border-bottom: 1px solid #e7e7e7;
      //display: flex;
      .top-right{
        display: flex;
        height: 35px;
        padding-top: 8px;
        margin-top: 7px;
        padding-left: 10px;
        background-color: #F5F5FF;
      }
      .colorSty{
        width: 40px;
        height: 20px;
        border-radius: 3px;
        margin-right: 10px;
        cursor: pointer;

      }
      //.top-icon {
      //  width: 10%;
      //  text-align: center;
      //  .icon {
      //    line-height: 200px;
      //    font-size: 40px;
      //    color: #929292;
      //    cursor: pointer;
      //  }
      //}
      .imgList {
        width: 100%;
        height: 560px;
        overflow-y: scroll;
        overflow-x: hidden;
        //display: flex;
        margin-top: 10px;


        .imgListDiv{
          width: 100%;
          border: 1px solid #d7d7d7;
          border-radius: 3px;
          cursor: pointer;
          margin: auto;
          height: 190px;
          padding-top: 15px;
          padding-left: 10px;
          .imgName{
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: #9c9b9b;
          }
        }
        .imgD {
          height: 140px;
          width: 140px;
          margin-right: 30px;
          margin-left: 5px;
        }
        .box-y {
          border-radius: 3px;
          position: absolute;
          top: 9px;
          left: 9px;
          width: 91%;
          height: 91%;
          background: rgba(0, 0, 0, 0.4) no-repeat center;
          display: none;
          .box-btn {
            color: white;
            margin: 50px 10px 10px 35px;
            font-size: 17px;
            font-weight: 550;
          }
        }
        .imgListDiv:hover .box-y {
          display: block;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .content-list {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .content-list-left{
        width: 35%;
        font-size: 18px;
        color: black;

      }
      .content-list-card{
        width: 65%;
        height: 550px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 50px;
        .content-list-card-imgList{
          background-color: black;
          //display: flex;
          //justify-content: center;
          //align-items: center;
          height: 110px;
          img{
            width: 80px;
            //height: 80px;
            margin-left: 12%;
            padding-top: 10px;
          }
          .font{
            color: #F7F9FA;
            text-align: center;
            margin-top: -10px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
